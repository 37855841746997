import axios, { AxiosResponse } from 'axios'
import { navigate } from 'gatsby'
import produce from 'immer'

import { LocalStorageKeys } from '@app/constants'

import {
  DocumentFileType,
  DocumentStatus,
  DocumentType,
  OfferStatuses,
  Routes,
  UserStatuses,
  ResetPasswordDto,
  AircraftDetailDto,
  AircraftReportsDto,
  AircraftSearchParams,
  AirportDetailDto,
  AirportFeeDto,
  AirportMapDto,
  AirportNote,
  AirwayDto,
  AviaCalculationsResponseDto,
  AviaFlightTimeRequestInput,
  AvoidedCountry,
  BaseLegDetailDto,
  BookingReportsDto,
  Cabotage,
  CabotageSearchParams,
  CalendarNoteDto,
  CalendarNoteSearchParams,
  ChatMessageDto,
  ChatSearchParams,
  ClientDetailDto,
  CompanyDto,
  ComputationResultDto,
  ContactPersonDetailDto,
  CopyAircraftRequestBody,
  CountryDto,
  CountrySearchParams,
  CreateAirportFeeBody,
  CreateAirportNoteBody,
  CreateAvoidedCountryInput,
  CreateCabotage,
  CreateCalendarNote,
  CreateChatMessageDto,
  CreateClientDto,
  CreateContactPersonDto,
  CreateCustomRouteDto,
  CreateFuelCostBody,
  CreateOffersFromComputationDto,
  CreateOffersFromComputationResponseDto,
  CreateOutageDto,
  CreateOvernightFeeBody,
  CreateRequestNoteBody,
  CreateResourceDto,
  CreateWarning,
  CurrencyDto,
  CurrencySearchParams,
  CustomRouteDetailDto,
  CustomRouteSearchParams,
  FuelCostDto,
  GetAirportFeesQuery,
  GetAirportsMapQuery,
  GetAirportsQuery,
  GetClientsQuery,
  GetCPQuery,
  GetFuelCostsQuery,
  GetOvernightFeeQuery,
  ImportAirportFeesBody,
  InitialRegistrationResponseDto,
  InitRegistrationBodyDto,
  LegSearchParams,
  MarkMessagesAsReadDto,
  OAuthSuccess,
  OfferComputationInputDto,
  OfferDetailDto,
  OfferDto,
  OperatorDto,
  OvernightFeeDto,
  PaginatedList,
  PaginatedScheduleListDto,
  PartialAircraftDto,
  PartialRequestDto,
  RegistrationBodyDto,
  ReportsSearchParams,
  RequestDetailDto,
  RequestNoteDto,
  RequestReportsDto,
  RequestResetPasswordDto,
  RequestSearchParams,
  SaveAirwayRequestInput,
  SaveAirwayRequestOutput,
  ScheduleDetailDto,
  ScheduleSearchParams,
  UpdateAirportFeeBody,
  UpdateAvoidedCountryInput,
  UpdateCalendarNote,
  UpdateClientDto,
  UpdateCompanyDto,
  UpdateContactPersonDto,
  UpdateCustomRouteDto,
  UpdateFuelCostBody,
  UpdateOperatorSpecificUserDataDto,
  UpdateOutageDto,
  UpdateOvernightFeeBody,
  UpdateRequestDto,
  UpdateWarning,
  UserDetailDto,
  UserDto,
  Warning,
  WarningSearchParams,
  FuelCostImportFileType,
  FleetScheduleOptimizatinDto,
} from '@strafos/common'

import {
  InvitationDto,
  PatchMarketplaceExtensionDto,
  TeamUserDto,
  UpdatePasswordDto,
  UpdateUserDisplaySettingsDto,
  UpdateUserDto,
} from '@app/utils/api/types'

import { LegComputationRequest } from '@strafos/common'

const GATSBY_BASE_API_URL = process.env.GATSBY_BASE_API_URL

const instance = axios.create({
  baseURL: GATSBY_BASE_API_URL,
})

enum ApiRoutes {
  Aircraft = 'aircraft',
  AirportFees = 'airport-fees',
  AirportNote = 'airport-note',
  Airports = 'airports',
  Airway = 'airway',
  Auth = 'auth',
  AviaCalculator = 'avia-calculator',
  AvoidedCountries = 'avoided-countries',
  Cabotage = 'cabotage',
  CalendarNote = 'calendar-note',
  Chat = 'chat',
  Client = 'clients',
  Companies = 'companies',
  Computations = 'computations',
  ContactPersons = 'contact-persons',
  Countries = 'countries',
  CreateOffersFromComputation = 'create-offers-from-computation',
  Currencies = 'currencies',
  CustomRoutes = 'custom-routes',
  Documents = 'documents',
  FuelCosts = 'fuel-costs',
  Invitations = 'invitations',
  My = 'my',
  Offers = 'offers',
  Operators = 'operators',
  OvernightFees = 'overnight-fees',
  Reports = 'reports',
  Requests = 'requests',
  Schedule = 'schedule',
  Users = 'users',
  Warnings = 'warnings',
  Webhook = 'webhook',
}

const PUBLIC_API_ENDPOINTS = [
  'auth/login',
  'users/registration/init',
  'users/registration/submit',
  'countries',
  'currencies',
]

const createAxiosRequestInterceptor = () => {
  return instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(LocalStorageKeys.AuthToken)

      if (!token && config.url && !PUBLIC_API_ENDPOINTS.includes(config.url)) {
        navigate(Routes.Login)

        return config
      }

      config.headers.Authorization = 'Bearer ' + token

      return config
    },
    (error) => Promise.reject(error),
  )
}

const createAxiosResponseInterceptor = () => {
  const responseInterceptor = instance.interceptors.response.use(
    (config) => config,
    async (error) => {
      if (
        error.response.status !== 401 ||
        error.config.url === `${ApiRoutes.Auth}/login`
      ) {
        return Promise.reject(error)
      }

      instance.interceptors.response.eject(responseInterceptor)

      try {
        const { data } = await instance.post(`${ApiRoutes.Auth}/refresh`)

        const { access_token } = data

        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + access_token

        localStorage.setItem(LocalStorageKeys.AuthToken, access_token)

        const originalRequestResult = await instance(error.config)

        return originalRequestResult
      } catch (error) {
        localStorage.removeItem(LocalStorageKeys.AuthToken)

        navigate(Routes.Login)
      } finally {
        createAxiosResponseInterceptor()
      }
    },
  )

  return responseInterceptor
}

createAxiosResponseInterceptor()
createAxiosRequestInterceptor()

type Response<Data = unknown> = Promise<AxiosResponse<Data>>

export const api = {
  // #region Auth
  login: (
    email: string,
    password: string,
    preferedOperatorId?: number,
  ): Response<{ access_token: string; operatorId: number }> => {
    return instance.post(`${ApiRoutes.Auth}/login`, {
      email,
      password,
      preferedOperatorId,
    })
  },

  logout: (): Response<void> => {
    return instance.post(`${ApiRoutes.Auth}/logout`)
  },

  requestPasswordChange: (params: RequestResetPasswordDto): Response<void> => {
    return instance.post(`${ApiRoutes.Auth}/request-password-change`, params)
  },

  logoutFromAllSessions: (token: string): Response<void> => {
    return instance.post(`${ApiRoutes.Auth}/logout-from-all-sessions`, {
      token,
    })
  },

  resetPassword: (params: ResetPasswordDto): Response<void> => {
    return instance.post(`${ApiRoutes.Auth}/reset-password`, params)
  },

  validateToken: (params: { token: string }): Response<void> => {
    return instance.get(`${ApiRoutes.Auth}/validate-token`, { params })
  },

  switchOperator: (operatorId: number): Response<{ access_token: string }> => {
    return instance.post(`${ApiRoutes.Auth}/switch-operator`, { operatorId })
  },
  // #endregion

  // #region Aircraft
  listAircraft: (
    params: AircraftSearchParams,
  ): Response<PaginatedList<AircraftDetailDto>> => {
    return instance.get(ApiRoutes.Aircraft, { params })
  },

  exportAircraft: (params: AircraftSearchParams): Response<Blob> => {
    return instance.get(`${ApiRoutes.Aircraft}/export`, {
      params,
      responseType: 'blob',
    })
  },

  getAircraftRegistrationCountry: (
    registrationCode: string,
  ): Response<CountryDto> => {
    return instance.get(`${ApiRoutes.Aircraft}/country-of-registration`, {
      params: { registrationCode },
    })
  },

  getAircraft: (id: number): Response<AircraftDetailDto> => {
    return instance.get(`${ApiRoutes.Aircraft}/${id}`)
  },

  listAircraftImages: (id: number): Response<{ id: number }[]> => {
    return instance.get(`${ApiRoutes.Aircraft}/${id}/pictures`)
  },

  getAircraftImage: (aircraftId: number, id: number): Response<Blob> => {
    return instance.get(`${ApiRoutes.Aircraft}/${aircraftId}/picture/${id}`, {
      responseType: 'blob',
    })
  },

  uploadAircraftImages: (
    aircraftId: number,
    files: FileList,
    max: number = 6,
  ): Response<{ status: 'ok' | 'nok' }> => {
    const formData = new FormData()
    const allowed = Math.min(files.length, max)
    for (let i = 0; i < allowed; i++) {
      formData.append('files', files.item(i)!)
    }
    return instance.patch(
      `${ApiRoutes.Aircraft}/${aircraftId}/pictures`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )
  },

  deleteAircraftImages: (
    aircraftId: number,
    images: number[],
  ): Response<{ status: 'ok' | 'nok' }> => {
    return instance.delete(`${ApiRoutes.Aircraft}/${aircraftId}/pictures`, {
      data: {
        pictureIds: images,
      },
    })
  },

  createAircraft: (params: AircraftDetailDto): Response<AircraftDetailDto> => {
    return instance.post(ApiRoutes.Aircraft, params)
  },

  updateAircraft: (
    id: number,
    params: PartialAircraftDto,
  ): Response<AircraftDetailDto> => {
    return instance.patch(`${ApiRoutes.Aircraft}/${id}`, params)
  },

  copyAircraft: (
    id: number,
    params: CopyAircraftRequestBody,
  ): Response<{ id: number }> => {
    return instance.post(ApiRoutes.Aircraft + `/${id}/copy`, params)
  },

  deleteAircraft: (id: number): Response => {
    return instance.delete(`${ApiRoutes.Aircraft}/${id}`)
  },

  // #endregion

  // #region AirportFees
  listAirportFees: (
    params: GetAirportFeesQuery,
  ): Response<PaginatedList<AirportFeeDto>> => {
    return instance.get(ApiRoutes.AirportFees, { params })
  },

  exportAirportFees: (params: GetAirportFeesQuery): Response<Blob> => {
    return instance.get(`${ApiRoutes.AirportFees}/export`, {
      params,
      responseType: 'blob',
    })
  },

  createAirportFee: (
    body: CreateAirportFeeBody,
  ): Response<CreateResourceDto> => {
    return instance.post(ApiRoutes.AirportFees, body)
  },

  importAirportFees: (
    body: ImportAirportFeesBody,
    file: File,
  ): Response<CreateResourceDto[]> => {
    const formData = new FormData()
    formData.append('file', file)
    body.aircraft_id &&
      formData.append('aircraft_id', body.aircraft_id.toString())
    return instance.post(`${ApiRoutes.AirportFees}/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },

  updateAirportFee: (
    id: number,
    data: UpdateAirportFeeBody,
  ): Response<AirportFeeDto> => {
    return instance.patch(`${ApiRoutes.AirportFees}/${id}`, data)
  },

  deleteAirportFee: (id: number): Response<void> => {
    return instance.delete(`${ApiRoutes.AirportFees}/${id}`)
  },
  //#endregion

  // #region AirportNote
  createOrUpdateAirportNote: (data: CreateAirportNoteBody) => {
    return instance.patch(ApiRoutes.AirportNote, data)
  },

  getAirportNotes: (): Response<AirportNote[]> => {
    return instance.get(ApiRoutes.AirportNote)
  },
  // #endregion

  // #region Airports
  listAirports: (
    params: GetAirportsQuery,
  ): Response<PaginatedList<AirportDetailDto>> => {
    return instance.get(`${ApiRoutes.Airports}`, { params })
  },

  getMapAirports: (params: GetAirportsMapQuery): Response<AirportMapDto[]> => {
    return instance.get(`${ApiRoutes.Airports}/map`, { params })
  },

  exportAirports: (params: GetAirportsQuery): Response<Blob> => {
    return instance.get(`${ApiRoutes.Airports}/export`, {
      responseType: 'blob',
      params,
    })
  },

  getTechStopAirports: (params: {
    tech_stops: string
  }): Response<AirportDetailDto[]> => {
    return instance.get(`${ApiRoutes.Airports}/techstops`, { params })
  },

  getAirport: (id: number): Response<AirportDetailDto> => {
    return instance.get(`${ApiRoutes.Airports}/${id}`)
  },
  //#endregion

  // #region Airway
  getAirway(id: number): Response<AirwayDto> {
    return instance.get(`${ApiRoutes.Airway}/${id}`)
  },

  saveAirway(body: SaveAirwayRequestInput): Response<SaveAirwayRequestOutput> {
    return instance.post(`${ApiRoutes.Airway}`, body)
  },

  getMultipleAirways(ids: number[]): Response<AirwayDto[]> {
    return ids.length > 0
      ? instance.get(`${ApiRoutes.Airway}`, {
          params: { ids: ids.join(',') },
        })
      : Promise.resolve({
          data: [],
          status: 200,
          statusText: 'OK',
          headers: {},
          config: {},
        })
  },
  // #endregion

  // #region AviaCalculator
  generateAviaPageCalculation(
    body: AviaFlightTimeRequestInput,
  ): Response<AviaCalculationsResponseDto> {
    return instance.post(`${ApiRoutes.AviaCalculator}`, body)
  },

  // #endregion

  // #region AvoidedCountries
  getAvoidedCountries(aircraftId?: number): Response<AvoidedCountry[]> {
    return instance.get(`${ApiRoutes.AvoidedCountries}`, {
      params: { aircraftId },
    })
  },

  createAvoidedCountry(body: CreateAvoidedCountryInput): Response<void> {
    return instance.post(`${ApiRoutes.AvoidedCountries}`, body)
  },

  updateAvoidedCountry(
    body: UpdateAvoidedCountryInput,
    id: string,
  ): Response<void> {
    return instance.patch(`${ApiRoutes.AvoidedCountries}/${id}`, body)
  },

  deleteAvoidedCountry(id: string): Response<void> {
    return instance.delete(`${ApiRoutes.AvoidedCountries}/${id}`)
  },
  // #endregion

  // #region Cabotage
  listCabotages(
    params: CabotageSearchParams,
  ): Response<PaginatedList<Cabotage>> {
    return instance.get(`${ApiRoutes.Cabotage}`, {
      params,
    })
  },

  createCabotage(body: CreateCabotage): Response<Cabotage[]> {
    return instance.post(`${ApiRoutes.Cabotage}`, body)
  },

  deleteCabotage(id: number): Response<void> {
    return instance.delete(`${ApiRoutes.Cabotage}/${id}`)
  },
  // #endregion

  // #region Calendar Note
  listCalendarNotes(
    params: CalendarNoteSearchParams,
  ): Response<CalendarNoteDto[]> {
    return instance.get(`${ApiRoutes.CalendarNote}`, {
      params,
    })
  },

  createCalendarNote(body: CreateCalendarNote): Response<CalendarNoteDto> {
    return instance.post(`${ApiRoutes.CalendarNote}`, body)
  },

  updateCalendarNote(id: number, body: UpdateCalendarNote): Response<void> {
    return instance.patch(`${ApiRoutes.CalendarNote}/${id}`, body)
  },

  deleteCalendarNote(id: number): Response<void> {
    return instance.delete(`${ApiRoutes.CalendarNote}/${id}`)
  },
  // #endregion

  // #region Chat
  getNewMessages: (
    newerThanSeconds: number = 60,
  ): Response<ChatMessageDto[]> => {
    return instance.get(`${ApiRoutes.Chat}/newMessages`, {
      params: { newerThanSeconds },
    })
  },

  getUnreadMessagesCount: (): Response<number> => {
    return instance.get(`${ApiRoutes.Chat}/unreadMessagesCount`)
  },

  markMessagesAsRead: (
    requests: MarkMessagesAsReadDto,
  ): Response<{ success: boolean; message?: string }> => {
    return instance.put(`${ApiRoutes.Chat}/markMessagesAsRead`, requests)
  },

  getChat: (
    params: ChatSearchParams,
  ): Response<PaginatedList<ChatMessageDto>> => {
    return instance.get(`${ApiRoutes.Chat}`, {
      params,
    })
  },

  createChatMessage: (body: CreateChatMessageDto): Response<{ id: number }> => {
    return instance.post(`${ApiRoutes.Chat}`, body)
  },
  // #endregion

  // #region Clients
  exportClients: (params: GetClientsQuery): Response<Blob> => {
    return instance.get(`${ApiRoutes.Client}/export`, {
      params,
      responseType: 'blob',
    })
  },

  listClients: (
    params: GetClientsQuery,
  ): Response<PaginatedList<ClientDetailDto>> => {
    return instance.get(ApiRoutes.Client, { params })
  },

  getClient: (id: number): Response<ClientDetailDto> => {
    return instance.get(`${ApiRoutes.Client}/${id}`)
  },

  createClient: (client: CreateClientDto): Response<ClientDetailDto> => {
    return instance.post(ApiRoutes.Client, client)
  },

  updateClient: (
    id: number,
    data: UpdateClientDto,
  ): Response<ClientDetailDto> => {
    return instance.patch(`${ApiRoutes.Client}/${id}`, data)
  },

  deleteClient: (id: number): Response<void> => {
    return instance.delete(`${ApiRoutes.Client}/${id}`)
  },
  // #endregion

  // #region Companies
  getCompany: (id: number): Response<CompanyDto> => {
    return instance.get(`${ApiRoutes.Companies}/${id}`)
  },

  updateCompany: (
    id: number,
    patchCompanyDto: UpdateCompanyDto,
  ): Response<CompanyDto> => {
    return instance.patch(`${ApiRoutes.Companies}/${id}`, patchCompanyDto)
  },
  // #endregion

  // #region Computations
  createComputation: (
    params: OfferComputationInputDto,
  ): Response<CreateResourceDto> => {
    return instance.post(ApiRoutes.Computations, params)
  },

  getComputation: (id: number): Response<ComputationResultDto> => {
    return instance.get(`${ApiRoutes.Computations}/${id}`)
  },
  // #endregion

  // #region ContacePersons
  listContactPersons: (
    params: GetCPQuery,
  ): Response<PaginatedList<ContactPersonDetailDto>> => {
    return instance.get(ApiRoutes.ContactPersons, { params })
  },

  createContactPerson: (
    contactPerson: CreateContactPersonDto,
  ): Response<ContactPersonDetailDto> => {
    return instance.post(ApiRoutes.ContactPersons, contactPerson)
  },

  updateContactPerson: (
    id: number,
    data: UpdateContactPersonDto,
  ): Response<ContactPersonDetailDto> => {
    return instance.patch(`${ApiRoutes.ContactPersons}/${id}`, data)
  },

  deleteContactPerson: (id: number): Response<void> => {
    return instance.delete(`${ApiRoutes.ContactPersons}/${id}`)
  },

  exportContactPersons: (
    params: Omit<GetCPQuery, 'limit' | 'page'>,
  ): Response<Blob> => {
    return instance.get(`${ApiRoutes.ContactPersons}/export`, {
      params,
      responseType: 'blob',
    })
  },
  // #endregion

  // #region Countries
  listCountries: (
    params: CountrySearchParams,
  ): Response<PaginatedList<CountryDto>> => {
    return instance.get(ApiRoutes.Countries, { params })
  },

  getCountryByCode: (code: string): Response<CountryDto> => {
    return instance.get(`${ApiRoutes.Countries}/code/${code}`)
  },
  // #endregion

  // #region CreateOffersFromComputation
  createOffersFromComputation: (
    params: CreateOffersFromComputationDto,
  ): Response<CreateOffersFromComputationResponseDto> => {
    return instance.post(ApiRoutes.CreateOffersFromComputation, params)
  },

  getOffer: (offerId: number): Response<OfferDetailDto> => {
    return instance.get(`/offers/${offerId}`)
  },

  getOffers: (offerIds: number[]): Response<OfferDetailDto[]> => {
    return offerIds.length > 0
      ? instance.get(`/offers/multiple/${offerIds.join(',')}`)
      : Promise.resolve({
          data: [] as OfferDetailDto[],
          status: 200,
          statusText: 'OK',
          headers: {},
          config: {},
        })
  },
  // #endregion

  // #region Currencies
  listCurrencies: (
    params: CurrencySearchParams,
  ): Response<PaginatedList<CurrencyDto>> => {
    return instance.get(ApiRoutes.Currencies, { params })
  },
  // #endregion

  // #region CustomRoutes
  listCustomRoutes: (
    params: CustomRouteSearchParams,
  ): Response<PaginatedList<CustomRouteDetailDto>> => {
    return instance.get(`${ApiRoutes.CustomRoutes}`, { params })
  },

  exportCustomRoutes: (
    params: Omit<CustomRouteSearchParams, 'limit' | 'page'>,
  ): Response<Blob> => {
    return instance.get(`${ApiRoutes.CustomRoutes}/export`, {
      params,
      responseType: 'blob',
    })
  },

  createCustomRoute: (body: CreateCustomRouteDto): Response<{ id: number }> => {
    return instance.post(ApiRoutes.CustomRoutes, body)
  },

  updateCustomRoute: (
    id: number,
    body: UpdateCustomRouteDto,
  ): Response<CustomRouteDetailDto> => {
    return instance.patch(`${ApiRoutes.CustomRoutes}/${id}`, body)
  },

  deleteCustomRoute: (id: number): Response<void> => {
    return instance.delete(`${ApiRoutes.CustomRoutes}/${id}`)
  },
  // #endregion

  // #region Documents
  generateOfferDocument(body: {
    offer_id: number
    type: DocumentType
    fileType: DocumentFileType
    user: UserDto | null
  }): Response<{ id: number; status: DocumentStatus }> {
    return instance.post(`${ApiRoutes.Documents}/generate`, body)
  },

  getDocumentStatus(body: {
    id: number
  }): Response<{ status: DocumentStatus }> {
    return instance.get(`${ApiRoutes.Documents}/status`, {
      params: { documentId: body.id },
    })
  },

  downloadDocument(body: { id: number }): Response<Blob> {
    return instance.get(`${ApiRoutes.Documents}/download`, {
      responseType: 'blob',
      params: { documentId: body.id },
    })
  },
  // #endregion

  // #region FuelCosts
  listFuelCosts(
    query: GetFuelCostsQuery,
  ): Response<PaginatedList<FuelCostDto>> {
    return instance.get(`${ApiRoutes.FuelCosts}`, { params: query })
  },

  importFuelCosts: (
    file: File,
    type: FuelCostImportFileType,
  ): Response<CreateResourceDto[]> => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('type', type)
    return instance.post(`${ApiRoutes.FuelCosts}/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },

  exportFuelCosts(query: GetFuelCostsQuery): Response<Blob> {
    return instance.get(`${ApiRoutes.FuelCosts}/export`, { params: query })
  },

  createFuelCost(data: CreateFuelCostBody): Response<CreateResourceDto> {
    return instance.post(`${ApiRoutes.FuelCosts}`, data)
  },

  updateFuelCost(id: number, data: UpdateFuelCostBody): Response<FuelCostDto> {
    return instance.patch(`${ApiRoutes.FuelCosts}/${id}`, data)
  },

  deleteFuelCost(id: number): Response<undefined> {
    return instance.delete(`${ApiRoutes.FuelCosts}/${id}`)
  },
  // #endregion

  // #region Invitations
  createInvitation: (postInvitationDto: InvitationDto) => {
    return instance.post(`${ApiRoutes.Invitations}`, postInvitationDto)
  },

  postResendInvitation: (operatorId: number, userId: number) => {
    return instance.post(`${ApiRoutes.Invitations}/resend`, {
      operator_id: operatorId,
      user_id: userId,
    })
  },
  // #endregion

  // #region My
  getMyOperators: (): Response<OperatorDto[]> => {
    return instance.get(`${ApiRoutes.My}/operators`)
  },

  putMyPassword: (params: UpdatePasswordDto): Response<void> => {
    return instance.put(`${ApiRoutes.My}/password`, params)
  },

  getMyUser: (): Response<UserDto> => {
    return instance.get(`${ApiRoutes.My}/user`)
  },

  patchMyUser: (
    partialUser: UpdateUserDto | UpdateUserDisplaySettingsDto,
  ): Response<UserDto> => {
    return instance.patch(`${ApiRoutes.My}/user`, partialUser)
  },

  updateOperatorSpecificUserData: (
    operatorId: number,
    data: UpdateOperatorSpecificUserDataDto,
  ) => {
    return instance.patch(`${ApiRoutes.My}/user/${operatorId}`, data)
  },
  // #endregion

  // #region Offers
  cancelOffer: (id: number): Response<OfferDetailDto> => {
    return instance.post(`${ApiRoutes.Offers}/${id}/cancel`)
  },

  reserveOffer: (offerId: number, date: Date): Response<void> => {
    return instance.post(`${ApiRoutes.Offers}/${offerId}/reserve`, {
      reservationDate: date,
    })
  },

  deleteOfferReservation: (offerId: number): Response<void> => {
    return instance.delete(`${ApiRoutes.Offers}/${offerId}/reserve`)
  },

  rebookOffer: (offerId: number, data: LegComputationRequest[]) => {
    return instance.post(`${ApiRoutes.Offers}/${offerId}/rebook`, {
      requests: data,
    })
  },

  bulkChangeAircraft: (
    aircraftId: number,
    requestIds: number[],
  ): Response<void> => {
    return instance.post(`${ApiRoutes.Offers}/bulk/changeAircraft`, {
      aircraftId,
      requestIds,
    })
  },

  updateOfferStatus: (
    offerId: number,
    nextStatus: OfferStatuses,
    shouldSendEmail?: boolean,
    userNote?: string,
    cancellationFee?: number | null,
    shouldSendToAvinode?: boolean,
  ) => {
    type Payload = {
      status: OfferStatuses
      user_note?: string
      should_send_email?: boolean
      should_send_to_avinode?: boolean
      cancellation_fee?: number
    }

    const payload: Payload = {
      status: nextStatus,
      should_send_email: shouldSendEmail,
      should_send_to_avinode: shouldSendToAvinode,
      user_note: userNote,
    }

    return instance.put(
      `${ApiRoutes.Offers}/${offerId}/status`,
      produce(payload, (draft) => {
        if (Number.isFinite(cancellationFee)) {
          draft.cancellation_fee = cancellationFee as number
        }
      }),
    )
  },

  updateOffer: (
    offerId: number,
    // @todo Create dedicated Patch DTO
    partialOffer: Omit<Partial<OfferDto>, 'legs'> & {
      legs: BaseLegDetailDto[]
    },
  ): Response<OfferDto> => {
    return instance.patch(`${ApiRoutes.Offers}/${offerId}`, partialOffer)
  },

  deleteOffer: (offerId: number): Response<void> => {
    return instance.delete(`${ApiRoutes.Offers}/${offerId}`)
  },
  // #endregion

  // #region Operators
  createOperator: (
    postOperatorDto: Partial<OperatorDto>,
  ): Response<OperatorDto> => {
    return instance.post(ApiRoutes.Operators, postOperatorDto)
  },

  updateOperator: (
    id: number,
    partialOperatorDto: Partial<OperatorDto>,
  ): Response<OperatorDto> => {
    return instance.patch(`${ApiRoutes.Operators}/${id}`, partialOperatorDto)
  },

  updateOperatorImage: (
    file: File,
    operatorId: number,
  ): Response<{ status: 'ok' | 'nok' }> => {
    const formData = new FormData()
    formData.append('file', file)
    return instance.patch(
      `${ApiRoutes.Operators}/${operatorId}/logo`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )
  },

  getOperatorImage: (operatorId: number): Response<Blob> => {
    return instance.get(`${ApiRoutes.Operators}/${operatorId}/logo`, {
      responseType: 'blob',
    })
  },
  // #endregion

  // #region OvernightFees
  listOvernightFees(
    query: GetOvernightFeeQuery,
  ): Response<PaginatedList<OvernightFeeDto>> {
    return instance.get(`${ApiRoutes.OvernightFees}`, { params: query })
  },

  exportOvernightFees(query: GetOvernightFeeQuery): Response<Blob> {
    return instance.get(`${ApiRoutes.OvernightFees}/export`, { params: query })
  },

  createOvernightFee(
    data: CreateOvernightFeeBody,
  ): Response<CreateResourceDto> {
    return instance.post(`${ApiRoutes.OvernightFees}`, data)
  },

  updateOvernightFee(
    id: number,
    data: UpdateOvernightFeeBody,
  ): Response<OvernightFeeDto> {
    return instance.patch(`${ApiRoutes.OvernightFees}/${id}`, data)
  },

  deleteOvernightFee(id: number): Response<undefined> {
    return instance.delete(`${ApiRoutes.OvernightFees}/${id}`)
  },
  // #endregion

  // #region Reports
  getRequestReports: (
    params: ReportsSearchParams,
  ): Response<RequestReportsDto> => {
    return instance.get(`${ApiRoutes.Reports}/requests`, { params })
  },

  getBookingReports: (
    params: ReportsSearchParams,
  ): Response<BookingReportsDto> => {
    return instance.get(`${ApiRoutes.Reports}/bookings`, { params })
  },

  getAircraftReport(
    params: ReportsSearchParams,
  ): Response<AircraftReportsDto[]> {
    return instance.get(`${ApiRoutes.Reports}/aircraft`, { params })
  },
  // #endregion

  // #region Requests
  listRequests: (
    params: RequestSearchParams,
  ): Response<PaginatedList<PartialRequestDto>> => {
    return instance.get(ApiRoutes.Requests, { params })
  },

  exportRequests: (
    params: Omit<RequestSearchParams, 'limit' | 'page'>,
  ): Response<Blob> => {
    return instance.get(`${ApiRoutes.Requests}/export`, {
      params,
      responseType: 'blob',
    })
  },

  bulkDeclineRequests: (params: number[]): Response<void> => {
    return instance.post(`${ApiRoutes.Requests}/bulk/decline`, { ids: params })
  },

  bulkQuoteRequests: (params: number[]): Response<void> => {
    return instance.post(`${ApiRoutes.Requests}/bulk/quote`, { ids: params })
  },

  getRequest: (id: number): Response<RequestDetailDto> => {
    return instance.get(`${ApiRoutes.Requests}/${id}`)
  },

  updateRequest: (requestId: number, params: UpdateRequestDto) => {
    return instance.patch(`${ApiRoutes.Requests}/${requestId}`, params)
  },

  addRequestNote: (params: CreateRequestNoteBody): Response<RequestNoteDto> => {
    return instance.patch(
      [ApiRoutes.Requests, params.request_id, 'add-note'].join('/'),
      params,
    )
  },

  declineRequest: (requestId: number): Response<void> => {
    return instance.post(`${ApiRoutes.Requests}/${requestId}/decline`)
  },

  postMarkChatMessageAsRead: ({
    requestId,
    latestMessageId,
  }: {
    requestId: number
    latestMessageId: number
  }): Response => {
    return instance.post(
      `${ApiRoutes.Requests}/${requestId}/chat/mark-as-read`,
      { latest_read_chat_message_id: latestMessageId },
    )
  },

  getSimilarRequests: (requestId: number): Response<RequestDetailDto[]> => {
    return instance.get(`${ApiRoutes.Requests}/${requestId}/similar`)
  },
  // #endregion

  // #region Schedule
  getScheduleList: (
    params: ScheduleSearchParams,
  ): Response<PaginatedScheduleListDto> => {
    return instance.get(ApiRoutes.Schedule, { params })
  },

  getScheduleListCsv: (
    params: Omit<ScheduleSearchParams, 'limit' | 'page'>,
  ): Response<Blob> => {
    return instance.get(`${ApiRoutes.Schedule}/export`, {
      params,
      responseType: 'blob',
    })
  },

  createOutage: (params: CreateOutageDto): Response<{ id: number }> => {
    return instance.post(`${ApiRoutes.Schedule}/outage`, params)
  },

  updateOutage: (
    id: number,
    params: UpdateOutageDto,
  ): Response<ScheduleDetailDto> => {
    return instance.patch(`${ApiRoutes.Schedule}/outage/${id}`, params)
  },

  deleteOutage: (id: number): Response<void> => {
    return instance.delete(`${ApiRoutes.Schedule}/outage/${id}`)
  },

  updateMarketplaceExtension: (
    id: number,
    params: PatchMarketplaceExtensionDto,
  ): Response<ScheduleDetailDto> => {
    return instance.patch(
      `${ApiRoutes.Schedule}/${id}/marketplace-extension`,
      params,
    )
  },

  deleteMarketplaceExtension: (id: number): Response<void> => {
    return instance.delete(`${ApiRoutes.Schedule}/${id}/marketplace-extension`)
  },

  optimizeFleet: (body: FleetScheduleOptimizatinDto): Response<CreateResourceDto> => {
    return instance.post(`${ApiRoutes.Schedule}/fleet-optimization`, body)
  },
  // #endregion

  // #region Users
  getUsers: (): Response<UserDetailDto[]> => {
    return instance.get(`${ApiRoutes.Users}`)
  },

  exportUsers: (): Response<Blob> => {
    return instance.get(`${ApiRoutes.Users}/export`, {
      responseType: 'blob',
    })
  },

  registerUser: (id: number, partialUser: UpdateUserDto): Response<UserDto> => {
    return instance.patch(`${ApiRoutes.Users}/${id}`, partialUser)
  },

  updateUserStatus: (
    userId: number,
    operatorId: number,
    status: UserStatuses,
  ): Response<void> => {
    return instance.put(
      `${ApiRoutes.Users}/${userId}/status`,
      { status },
      { params: { operator_id: operatorId } },
    )
  },

  getUser: (id: number): Response<UserDetailDto[]> => {
    return instance.get(`${ApiRoutes.Users}/${id}`)
  },

  updateUser: (userId: number, updateTeamUser: TeamUserDto) => {
    return instance.patch(`${ApiRoutes.Users}/${userId}`, updateTeamUser)
  },

  initRegistration: (
    data: InitRegistrationBodyDto,
  ): Response<InitialRegistrationResponseDto> => {
    return instance.post(`${ApiRoutes.Users}/registration/init`, data)
  },

  submitRegistration: (
    data: RegistrationBodyDto,
  ): Response<{ access_token: string }> => {
    return instance.post(`${ApiRoutes.Users}/registration/submit`, data)
  },
  // #endregion

  getReservedLegs: (
    opts: LegSearchParams,
  ): Response<{ schedule: ScheduleDetailDto[] }> => {
    return instance.get(`/leg`, {
      params: {
        reserved: true,
        ...opts,
      },
    })
  },

  // #region Warnings
  listWarnings(params: WarningSearchParams): Response<Warning[]> {
    return instance.get(`${ApiRoutes.Warnings}`, {
      params,
    })
  },

  createWarning(body: CreateWarning): Response<void> {
    return instance.post(`${ApiRoutes.Warnings}`, body)
  },

  updateWarning(id: number, body: UpdateWarning): Response<void> {
    return instance.patch(`${ApiRoutes.Warnings}/${id}`, body)
  },

  deleteWarning(id: number): Response<void> {
    return instance.delete(`${ApiRoutes.Warnings}/${id}`)
  },
  // #endregion

  // #region Webhook
  authLeonStart(leonSlug: string): Response<string> {
    return instance.get(
      `${ApiRoutes.Webhook}/auth/leon/auth-request?leonSlug=${leonSlug}`,
    )
  },

  authLeonSuccess(body: OAuthSuccess): Response<Warning[]> {
    return instance.post(`${ApiRoutes.Webhook}/auth/leon/auth-success`, body)
  },
  // #endregion
}
